import { Tooltip, Select, message } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';
import { getStatusStyles } from './getStatusStyles';
import { ReactComponent as DeleteIcon } from './../../../../../../utils/icons/DeleteIconConfigurable.svg';
import { ReactComponent as EditPenIcon } from './../../../../../../utils/icons/EditPenIconConfigurable.svg';
import { ReactComponent as PipeIcon } from './../../../../../../utils/icons/Pipe_Icon.svg';
import { ReactComponent as UpBraces } from './../../../../../../utils/icons/UpBraces.svg';

import { getFormattedDate } from '../../../../../../utils/helpers/utils';
import { useState } from 'react';
import { postRequest } from '../../../../../../utils/handler/apiHandler';

const { Option } = Select;

export const getIssuesAndSubIssuesTableColumns = (
  editIssues: (payload: any) => void,
  deleteIssues: (payload: any) => void,  
  issuesStatusList: any[],
  ticketId: string, 
  jobCardId: number, 
  setFetchTicketDataCnt: (cnt:number) => void,
  serviceStatusUpdateDate: string,
): ColumnsType<any> => {
  
  const [loader, setLoader] = useState(false);
  const [showIssuesStatusOption, setShowIssuesStatusOption] = useState<number>(-1);
  const [jobId, setJobId] = useState<number>(0);
  const [selectedSubIssueId, setSelectedSubIssueId] = useState<number>(0);
  
  const handleIssueStatusChange = async (value: string) => {
    if (!value) {
      message.error("Please enter a valid Issue and Subissue.");
      return;
    }

    try {
        const paylaod = [{
            id: jobId,
            subIssueId: selectedSubIssueId,
            status: issuesStatusList?.find((issue: any)=> issue.id == value)?.label,
        }]
        setLoader(true);
        const response = await postRequest(
            `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/ticket/job-card-issue?ticketId=${ticketId}`,
            paylaod
        );

        setFetchTicketDataCnt(Math.random());
        if (response.status !== 201) {
            message.error(response.msg || "Failed to update Issue Status");
        }
    } catch (error) {
        message.error("An error occurred while updateing Issue Status");
    } finally {
        setLoader(false);
        setShowIssuesStatusOption(-1);
    }
  }

  const outsideSelectClicked = () => {
    setShowIssuesStatusOption(-1);
    setJobId(0);
    setSelectedSubIssueId(0);
  }

  const issueCreatedBeforeStatusChange = (reportedAt: string, serviceStatusUpdateDate: string): boolean => {
    
    // Convert both strings to Date objects
    const reportedAtDate = new Date(reportedAt);
    const serviceStatusUpdateDateObj = new Date(serviceStatusUpdateDate);

    // Compare the two dates
    return reportedAtDate < serviceStatusUpdateDateObj;
}


  return [
    {
      title: (
        <div style={{ display: 'flex', marginLeft: '5px' }}>
          <span style={{}}>Issue</span>
        </div>
      ),
      dataIndex: 'issue',
      key: 'issue',
      width: '15%',
      render: (text: string) => (
        <Tooltip title={text}>
          <div style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            {text}
          </div>
        </Tooltip>
      ),
    },
    {
      title: (
        <div style={{ display: 'flex' }}>
          <span style={{}}>Sub Issue</span>
        </div>
      ),
      dataIndex: 'subissue',
      key: 'subissue',
      width: '18%',
      render: (text: string) => (
        <Tooltip title={text}>
          <div style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            {text}
          </div>
        </Tooltip>
      ),
    },
    {
        title: (
          <div style={{ display: 'flex'}}>
            <span style={{}}>Reported At</span>
          </div>
        ),
        dataIndex: 'reportedAt',
        key: 'reportedAt',
        width: '18%',
        render: (text: string) => text != null ? `${getFormattedDate(text)} ` : <span style={{fontSize: 12}}>NA</span>,
    
    },

    {
      title: (
        <div style={{ display: 'flex'}}>
          <span style={{}}>Status</span>
        </div>
      ),
      dataIndex: 'status',
      key: 'status',
      width: '15%',
      render: (text: any, record: any, index: number) => {
        const { color, backgroundColor } = getStatusStyles(text?.id);
        return (
          <div style={{ display: "flex", alignItems: "center", overflow: 'hidden', whiteSpace: 'nowrap' }}>
            {
              showIssuesStatusOption == index ?
                <Select 
                  value={text}
                  onChange={handleIssueStatusChange}
                  style={{ width: '8rem', overflow: 'hidden', textOverflow: 'ellipsis' }}
                  onBlur={outsideSelectClicked}
                  autoFocus={true}
                >
                  {
                    issuesStatusList?.map((status: any) => (
                      <Option key={status.id} value={status.id} style={{ color: status.color, backgroundColor: status.bgColor, margin: '0.2rem' }}>
                        {status.label}
                      </Option>
                    ))
                  }
                </Select> :
                <Tooltip title={text}>
                  <div 
                    onClick={() => {
                      setShowIssuesStatusOption(index);
                      setJobId(record?.jobId);
                      setSelectedSubIssueId(record?.subissueId);
                    }} 
                    style={{
                      width: '5.2rem',
                      color: text ? record?.statusColor : 'brown',
                      backgroundColor: text ? record?.backgroundColor : '#d6d695',
                      padding: '2px 6px',
                      borderRadius: '4px',
                      cursor: 'pointer',
                      overflow: 'hidden',  // Ensure overflow is hidden
                      textOverflow: 'ellipsis', // Enable ellipsis effect
                      whiteSpace: 'nowrap', // Prevent wrapping and enable ellipsis
                      display: 'flex', // Ensure the content stays in a single row
                      alignItems: 'center', // Vertically center the text
                    }}
                  >
                    <span style={{
                      marginRight: '2px', 
                      overflow: 'hidden', 
                      textOverflow: 'ellipsis',
                      flexGrow: 1,  // Ensure the span takes available space
                    }}>
                      {text ? text : 'NA'}
                    </span>
                    <DownOutlined style={{ fontSize: '0.5rem', color: 'grey' }} />
                  </div>
                </Tooltip>
              
            }
          </div>

        );
      },
  },
    
    {
        title: (
          <div style={{ display: 'flex'}}>
            <span style={{}}>Last Updated At</span>
          </div>
        ),
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        width: '18%',
        render: (text: string) => text != null ? `${getFormattedDate(text)} ` : <span style={{fontSize: 12}}>NA</span>,
        sortDirections: ['ascend', 'descend'],
        
    },

    {
      title: (
        <div style={{ display: 'flex'}}>
          <span style={{}}>Actions</span>
        </div>
      ),
      key: 'action',
      width: '10%',
      align: "left",
      render: (text: string, record: any) => (
        <div style={{ display: "flex", justifyContent: "flex-start" }}>
          {
            issueCreatedBeforeStatusChange(record?.reportedAt, serviceStatusUpdateDate) ?
              // Case when true: Disabled state with pointer on hover
              <div>
                <Tooltip placement="bottom" title={"Delete Issue"}>
                  <a 
                    style={{  color: 'grey', cursor: 'not-allowed' }}
                  >
                    <DeleteIcon style={{ marginRight: '0.6rem', color: 'grey' }} />
                  </a>
                </Tooltip>
                
                <PipeIcon 
                  style={{ marginRight: '0.6rem', color: 'grey', marginTop: '2px' }} 
                />
                
                <Tooltip placement="bottom" title={"Edit Issue"}>
                  <EditPenIcon 
                    style={{ 
                      marginRight: '0.6rem', 
                      marginTop: '4px', 
                      color: 'grey', 
                      cursor: 'not-allowed' 
                    }} 
                  />
                </Tooltip>
              </div>
              :
              // Case when false: Normal state
              <div>
                <Tooltip placement="bottom" title={"Delete Issue"}>
                  <a onClick={() => deleteIssues(record)}>
                    <DeleteIcon style={{ marginRight: '0.6rem' }} />
                  </a>
                </Tooltip>
                
                <PipeIcon style={{ marginRight: '0.6rem', marginTop: '2px' }} />
                
                <Tooltip placement="bottom" title={"Edit Issue"}>
                  <EditPenIcon 
                    onClick={() => editIssues(record)} 
                    style={{ marginRight: '0.6rem', marginTop: '4px' }} 
                  />
                </Tooltip>
              </div>
          }
        </div>


      )
    },
    
  ] ;
};
