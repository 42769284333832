import { DeleteFilled, DownloadOutlined, DownOutlined, EditOutlined, EyeInvisibleFilled, EyeOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Form, Input, message, Row, Select, Space, Tooltip, Typography, Upload } from "antd";
import { useEffect, useState } from "react";
import axios from "axios";
import { authSelector } from "../../../../../../features/auth/authSlice";
import { useAppSelector } from "../../../../../../app/hooks";
import ViewDocumentModal from "../../../../Client/ViewDocumentModal";
import moment from "moment";
import dayjs from "dayjs";
import { handleFileDownload } from "../../../../../../utils/handler/apiHandler";

interface PropType {
    prospectId: number,
    jobCardId: number,
    ticketDetails: any,
    ticketStatusId: number,
    setEditingInvoiceForm: (value: boolean) => void
    editingInvoiceForm: boolean,
    getUpdatedData: () => void
    edittingForm: boolean
}

export default function InvoiceDetailsForm({ prospectId, jobCardId, ticketDetails, ticketStatusId, setEditingInvoiceForm, editingInvoiceForm, getUpdatedData, edittingForm }: PropType) {
    const auth = useAppSelector(authSelector);

    const [submitting, setSubmitting] = useState(false)
    const [fileList, setFileList] = useState<any>([])
    const [fileUrls, setFileUrls] = useState<any>([])
    const [uploading, setUploading] = useState(false)
    const [ongoingUploads, setOngoingUploads] = useState(0)
    const [previewFile, setPreviewFile] = useState<null | {
        "fileName": string,
        "type": string,
        "url": string,
    }>(null);
    const [form] = Form.useForm()
    const [invoiceStatus, setInvoiceStatus] = useState("")

    useEffect(()=>{
            if(editingInvoiceForm && edittingForm) {
                handleCancelForm()
            }
        }, [edittingForm])


    const handleUploadRequest = async (file: any) => {
        form.setFields([
            {
              name: 'invoiceDocument',
              errors: [],
            },
          ]);
        const url = `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/upload/invoice`;
        let formData = new FormData();
        formData.append('file', file.file);
        formData.append('prospectId', prospectId as any);
        // formData.append('type', 'payment');
        setUploading(true)
        setOngoingUploads((prev) => prev + 1);
        try {
            const response = await axios.post(url, formData, {
                headers: {
                    'Authorization': `Bearer ${auth.accessToken}`,
                    'Content-Type': 'multipart/form-data'
                }
            }).then(response => {
                if (response.status === 200) {
                    setFileUrls((prevState: any) => [...prevState, response?.data?.DocumentUrl])
                    setFileList((prevState: any) => [...prevState, file.file]);
                }
                else {
                    console.log("error in uploading file")
                }
            })
        } catch (error) {
            console.error('Upload error:', error);
        } finally {
            setOngoingUploads((prev) => {
                const newOngoingUploads = prev - 1;
                if (newOngoingUploads === 0) {
                    setUploading(false);
                }
                return newOngoingUploads;
            });
        }
    };

    const handleDelete = (file: any) => {

        const tempFileList = [];
        const tempFileUrls: string[] = []
        for (let i = 0; i < fileList?.length; i++) {
            if (fileList[i]?.uid !== file?.uid) {
                tempFileUrls.push(fileUrls?.[i])
                tempFileList.push(fileList[i]);
            }
        }
        setFileUrls(tempFileUrls)
        setFileList(tempFileList);
    }


    const saveInvoiceDetails = async () => {
        if(fileUrls.length === 0 && invoiceStatus === "Invoice Available") {
            form.setFields([
                {
                  name: 'invoiceDocument',
                  errors: ['Upload Invoice Documents'],
                },
              ]);
        } else {
            setSubmitting(true)
            const url = `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/workshop/invoice?jobCardId=${jobCardId}&urlList=${encodeURIComponent(fileUrls)}`;
            const data = {
                "invoiceStatus": form.getFieldValue("invoiceStatus"),
                "invoiceAmount": form.getFieldValue("invoiceAmount"),
                "invoiceNumber": form.getFieldValue("invoiceNo"),
                "invoiceDate": form.getFieldValue("invoiceDate") && dayjs(form.getFieldValue("invoiceDate")).format("YYYY-MM-DD"),
                "invoiceRemark": form.getFieldValue("remarks")
            }
            try {
                const response = await axios.post(url, data, {
                    headers: {
                        'Authorization': `Bearer ${auth.accessToken}`,
                    }
                }).then(response => {
                    if (response.status === 201) {
                        setSubmitting(false)
                        setFileList([])
                        setFileUrls([])
                        setEditingInvoiceForm(false)
                        getUpdatedData()
                    }
                    else {
                        console.log("error in uploading file")
                        setSubmitting(false)
                    } 

                })
            } catch (error) {
                console.error('Upload error:', error);
                setSubmitting(false)
            }
        }
    }

    useEffect(() => {
       setFormValues()
    }, [ticketDetails])

    const setFormValues = () => {
        console.log("ticketDetails", ticketDetails)
        setInvoiceStatus(ticketDetails?.jobCard?.invoiceStatus)
        form.setFieldsValue({
            "invoiceStatus": ticketDetails?.jobCard?.invoiceStatus,
            "invoiceAmount": ticketDetails?.jobCard?.invoiceAmount,
            "invoiceNo": ticketDetails?.jobCard?.invoiceNumber,
            "invoiceDate": ticketDetails?.jobCard?.invoiceDate && dayjs(ticketDetails?.jobCard?.invoiceDate),
            "remarks": ticketDetails?.jobCard?.invoiceRemark
        })
        setFileUrls(ticketDetails?.jobCard?.invoiceUrlList || [])
        if (ticketDetails?.jobCard?.invoiceUrlList) {
            const urls = ticketDetails?.jobCard?.invoiceUrlList;
            const result = urls.map((url: string) => {
                const filename = url.split('/').pop(); // Extract filename from the URL
                const [name, ext]: any = filename?.split('.'); // Split filename into name and extension
                return { name: name, type: ext };
            });

            setFileList(result);
        } else {
            setFileList([])
        }
    }

    const handleCancelForm = () => {
        setFormValues()
        setEditingInvoiceForm(false)
    }

    return (
        <>
            <div style={{ marginTop: 40 }}>
                <Typography.Text style={{ fontSize: 16, fontWeight: "bold", color: "#464646" }}>Invoice Details</Typography.Text>
            </div>

            <div style={{
                position: "relative",
                background: "white", padding: "20px", borderRadius: 8, boxShadow: "0px 0px 24px 0px rgba(0, 0, 0, 0.05)",
                marginTop: 20,
            }}>
                <Form
                    className="client-onboarding-form"
                    disabled={ticketStatusId === 12 || !editingInvoiceForm}
                    onFinish={saveInvoiceDetails}
                    form={form} layout="vertical" name="workshop-details"
                    autoComplete="off"
                >
                    <Row gutter={20}>
                    <Col span={6}>
                            <Form.Item
                                name="invoiceStatus"
                                label={<b>Invoice Status</b>}
                                rules={[{ required: true, message: 'Please enter Invoice Status' }]}
                            >
                                <Select 
                                    onChange={(value) => {form.validateFields(); setInvoiceStatus(value)}}
                                    placeholder="Select Status" style={{ width: "100%" }}
                                >
                                    <Select.Option value="Invoice Available">Invoice Available</Select.Option>
                                    <Select.Option value="Invoice Not Available">Invoice Not Available</Select.Option>
                                    <Select.Option value="Free Service">Free Service</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="invoiceNo"
                                label={<b>Invoice No. </b>}
                                rules={[{ required: invoiceStatus === "Invoice Available", message: 'Enter Invoice No.' }]}
                            >
                                <Input placeholder="Enter No." />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="invoiceAmount"
                                label={<b>Total Invoiced Amount </b>}
                                rules={[{ required: invoiceStatus === "Invoice Available" || invoiceStatus === "Invoice Not Available", message: 'Enter Invoiced Amount' }]}
                            >
                                <Input placeholder="Enter Amount" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="invoiceDate"
                                label={<b>Invoice Date</b>}
                                rules={[{ required: invoiceStatus === "Invoice Available", message: 'Please enter Invoice Date' }]}
                            >
                                <DatePicker placeholder="Select Date" style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="invoiceDocument"
                                label={<b>Invoice Document</b>}
                                rules={[{ required: (invoiceStatus === "Invoice Available" && fileUrls.length === 0), message: 'Please upload Invoice Document' }]}
                            >
                                <Upload
                                    accept=".docx,.doc,.pptx,.ppt,.xlsx,.xls,.pdf,.jpg,.jpeg,.png"
                                    style={{ width: "100%", display: "block", }}
                                    customRequest={(file) => handleUploadRequest({ file })}
                                    beforeUpload={(file) => {
                                        if (file.size > 100 * 1024 * 1024) {
                                            // You can show a custom error message or a notification to the user
                                            message.error('File size must be smaller than 100MB!');
                                            return false; // Prevent upload
                                        }
                                        handleUploadRequest({ file })
                                        return false
                                    }}
                                    fileList={fileList}
                                    onRemove={handleDelete}
                                    itemRender={(originNode, file, fileList2, actions) => <div style={{ width: "100%", display: "flex", justifyContent: "space-between", marginTop: 8 }}>
                                        <div style={{ width: "100%", textAlign: "left" }}>
                                            <Typography.Text ellipsis>
                                                <Tooltip title={file.name}>
                                                    <Typography.Text style={{ width: 100 }} ellipsis>{file.name}</Typography.Text>
                                                </Tooltip>
                                            </Typography.Text>
                                        </div>
                                        <div style={{ display: "flex", gap: 10 }}>
                                            {["application/pdf", "image/jpeg", "image/jpg", "image/png", "pdf", "jpeg", "jpg", "png"].includes(file.type as string) ? <div onClick={() => {
                                                const itemIndex = fileList2.findIndex((i) => i.uid === file.uid);
                                                setPreviewFile({
                                                    "fileName": file.name,
                                                    "type": file.type as string,
                                                    "url": fileUrls[itemIndex]?.replace(/^[*]+/, "")
                                                })
                                            }} style={{ cursor: "pointer" }}>
                                                <EyeOutlined />
                                            </div> :
                                                <DownloadOutlined 
                                                    onClick={()=>{
                                                        const itemIndex = fileList2.findIndex((i) => i.uid === file.uid);
                                                        console.log("fileUrls[itemIndex]",fileUrls[itemIndex])
                                                        const fileNameWithExtension = fileUrls[itemIndex].substring(fileUrls[itemIndex].lastIndexOf('/') + 1)
                                                        const fileNameWithoutExtension = fileNameWithExtension.substring(0, fileNameWithExtension.lastIndexOf('.'));
                                                        const fileType = fileUrls[itemIndex].split('.').pop()

                                                        handleFileDownload(fileUrls[itemIndex]?.replace(/^[*]+/, ""),fileNameWithoutExtension,fileType as any)}
                                                    } 
                                                />
                                            }

                                            {editingInvoiceForm && <Button disabled={uploading || ticketStatusId === 12} onClick={actions.remove} size="small" type="text" style={{ cursor: "pointer" }}>
                                                <DeleteFilled style={{ color: (uploading || ticketStatusId === 12) ? "grey" : "red" }} />
                                            </Button>}
                                        </div>
                                    </div>}
                                    listType="text"
                                    multiple={true}
                                >

                                    {ticketStatusId !== 12 && editingInvoiceForm && <Button
                                        style={{ position: "absolute", top: 0, width: "100%", }}
                                        loading={uploading}
                                        disabled={uploading}
                                        icon={<PlusOutlined />}
                                    >
                                        Upload
                                    </Button>}

                                </Upload>

                            </Form.Item>
                        </Col>
                        <Col span={8}></Col>
                        <Col span={24}>
                            <Form.Item
                                name="remarks"
                                label={<b>Closing Reamrks</b>}
                                rules={[{ required: false, message: 'Please enter Schedule Service Date Slot' }]}
                            >
                                <Input.TextArea placeholder="Enter Remarks" />
                            </Form.Item>
                        </Col>

                        {editingInvoiceForm &&
                            <>
                                {ticketStatusId !== 12 && ticketStatusId == 9 && <Col style={{ textAlign: "right" }} span={24}>
                                    <Space>
                                        <Button onClick={handleCancelForm} type="default">Cancel</Button>
                                        <Button disabled={submitting} loading={submitting} htmlType="submit" type="primary">
                                            Save Invoice Details
                                        </Button>
                                    </Space>

                                </Col>}
                            </>}


                    </Row>
                </Form>

                {!editingInvoiceForm && ticketStatusId == 9 && <Row>
                    <Col style={{ textAlign: "right" }} span={24}>
                        <Button icon={<EditOutlined />} onClick={() => setEditingInvoiceForm(true)} type="primary">Edit Details</Button>
                    </Col>
                </Row>}



            </div>
            <ViewDocumentModal setPreviewFile={setPreviewFile} previewFile={previewFile} />
        </>
    )
}