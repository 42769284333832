import { Table, message } from "antd";
import { getIssuesAndSubIssuesTableColumns } from "../Utils/getIssuesAndSubIssuesTableColumns";
import { useEffect, useState } from "react";
import axios from "axios";
import EditIssues from "./EditIssues";
import { getRequest } from "../../../../../../utils/handler/apiHandler";

interface IssuesAndSubIssuesTableProps{
  issueSubissueTableData: any,
  setFetchTicketDataCnt: (cnt: number) => void;
  ticketId: string,
  jobCardId: number,
  serviceStatusUpdateDate: string,
}

const IssuesAndSubIssuesTable: React.FC<IssuesAndSubIssuesTableProps> = ({issueSubissueTableData, 
  setFetchTicketDataCnt, ticketId, jobCardId, serviceStatusUpdateDate}) => {

  const [tableHeight, setTableHeight] = useState<number>(500);
  const [loader, setLoader] = useState(false);
  const [showEditIssue, setShowEditIssue] = useState(false);
  const [jobId, setJobId] = useState<number>(0);
  const [issueId, setIssueId] = useState<string>("");
  const [subissueId, setSubissueId] = useState<string>("");
  const [issuesStatusList, setIssuesStatusList] = useState<any[]> ([]);
  
  useEffect(()=>{
    const fetchStatusList = async () => {
      try {
          setLoader(true);
          // const filter = encodeURIComponent(`sj.job_card_id|eq|${jobCardId}`);
          const response = await getRequest(
              `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/enum?enumClass=SubIssueStatus`
          );

          if (response.status !== 200) {
              message.error(response.msg || "Failed to fetch issuesStatusList of issues.");
          }
          const items = response?.data;
          setIssuesStatusList(items);
      } catch (error) {
          message.error("An error occurred while fetching issuesStatusList.");
      } finally {
          setLoader(false);
      }
    }
    fetchStatusList();
  },[]); 
  
  const editIssues = (payload:any) => {
    setIssueId(payload?.issueId)
    setSubissueId(payload?.subissueId)
    setJobId(payload?.jobId)
    setShowEditIssue(true);
    
  }

  const deleteIssues = async (payload:any)=>{
    try {
      setLoader(true);
      const response: any = await axios.delete(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/ticket/job-card-issue?id=${payload?.jobId}`);
      if (response.status !== 200) {
          message.error(response.msg || "Failed to  delete  issue.");
      }
    } catch (error) {
        message.error("An error occurred while delete Issue.");
    } finally {
        setLoader(false);
        setFetchTicketDataCnt(Math.random());
    }
  }

  const onCloseEditIssue = ()=>{
    setShowEditIssue(false);
}

  const columns = getIssuesAndSubIssuesTableColumns(editIssues, deleteIssues,
      issuesStatusList, ticketId, jobCardId, setFetchTicketDataCnt, serviceStatusUpdateDate);
  
    
    return (
        <>
            <Table
                columns={columns}
                dataSource={issueSubissueTableData || []}
                scroll={{ x: 'max-content', y: 0.80 * tableHeight }}
                pagination= {false}
                size="middle"
                components={{
                  header: {
                    wrapper: (props: any) => <thead style={{ backgroundColor: '#CEE6FA', height: '3.5rem' }}>{props.children}</thead>,
                  },
                }}
              />
            {showEditIssue && (
                <EditIssues setFetchTicketDataCnt={setFetchTicketDataCnt} 
                setShowEditIssue={setShowEditIssue} ticketId={ticketId} jobCardId={jobCardId}
                issueId={issueId} subIssueId={subissueId} showEditIssue={showEditIssue}
                onCloseEditIssue={onCloseEditIssue} jobId={jobId}/>
            )}
        </>
    );
}
export default IssuesAndSubIssuesTable;