import { ReactComponent as LessthanIcon } from './../../../../../..//src/utils/icons/LessthanIcon.svg';
import { ReactComponent as CrossIcon } from './../../../../../../src/utils/icons/Cross_Icon.svg';
import { Button, Tooltip, message } from 'antd';
import { useState } from 'react';
import { getRequest, postRequest, putRequest } from '../../../../../utils/handler/apiHandler';
import { formatDateForTicket, getFormatedDateTimeWithDecreasedTime, getFormatedDateTimeWithoutChangingTime, getFormattedDate } from '../../../../../utils/helpers/utils';
import StartServiceConfirmationModal from './StartServiceConfirmationModal';
import ServiceArrivalTimeConfirmationModal from './ServiceArrivalTimeConfirmationModal';
import ScheduleServiceConfirmationModal from './ScheduleServiceConfirmationModal';
import ServiceScheduleTimeConfirmationModal from './ServiceScheduleTimeConfirmationModal';
import CompleteJobModal from '../IssueDetails/CompleteJobModal';
import ResolveJobModal from '../IssueDetails/IssueAndSubIssues/ResolveJobModal';
import { useNavigate } from "react-router-dom";
import CloseTicketCancelOrResolveModel from './CloseTicketCancelOrResolveModel';
import CloseTicketWithRemarkModal from './CloseTicketWithRemarkModal';
import ResolveTicketWithRemarkModal from './ResolveTicketWithRemarkModal';
import dayjs from 'dayjs';

interface TicketDetailsHeaderProps{
    ticketId: string,
    ticketHeaderDetails: any;
    setFetchTicketDataCnt: (cnt: number) => void;
    jobCardId: number,
    prospectId: number,
    disableScheduleService: boolean,
    isPickupAndDrop: boolean,
    actualPickup: string,
    deliverySchedule: string,
    ticketDetails:any,
    disableHeaderActions: boolean,
    edititngForm: boolean,
    editingInvoiceForm: boolean,
}
const TicketDetailsHeader: React.FC<TicketDetailsHeaderProps> = ({ ticketId, ticketHeaderDetails, setFetchTicketDataCnt, jobCardId,
         prospectId, disableScheduleService, isPickupAndDrop, ticketDetails, actualPickup, deliverySchedule, disableHeaderActions, edititngForm, editingInvoiceForm}) => {
    const [loader, setLoader] = useState(false);
    const { ticketNo, prospectName, lastUpdatedAt, ticketStatus, ticketStatusId, ticketStatusColor, ticketStatusBackgroundColor } = ticketHeaderDetails || {};
    const [arrivalTime, setArrivalTime] = useState<Date | null>(null);
    const [scheduleTime, setScheduleTime] = useState<Date | null>(null);
    const [closeTicketRemark, setCloseTicketRemark] = useState<string>('');
    const [resolveTicketRemark, setResolveTicketRemark] = useState<string>('');
    const [startServiceFlag, setStartServiceFlag] = useState<number> (0);
    const [scheduleServiceFlag, setScheduleServiceFlag] = useState<number> (0);
    const [showJobCompleteModal, setShowJobCompleteModal] = useState(false)
    const [showResolveModal, setShowResolveMoldal] = useState(false)
    const [closeTicketOrResolveFlag, setCloseTicketOrResolveFlag] = useState<number> (0);
    const [checkingIfCanResolveJOb, setCheckingIfCanResolveJob] = useState<boolean>(false);
    const navigate = useNavigate();

        const STATUS_IDS = {
            TICKET_CREATED: 5,
            SCHEDULED: 6,
            SCHEDULED_OVERDUE: 7,
            IN_SERVICING: 8,
            JOB_COMPLETED: 9,
            DATA_INSUFFICIENT: 4,
            SKIPPED: 3,
        };          

    const handleScheduleServiceDateConfirm = async () => {
        try {
            setLoader(true);
            const filter = encodeURIComponent('name|like|issue');
            const response = await putRequest(
                `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/ticket?id=${ticketId}`,
                {
                    "serviceStatus":"Scheduled",
                    "genericJobCardDto":{
                        "pickupScheduledDate": (isPickupAndDrop && scheduleTime) ? getFormatedDateTimeWithoutChangingTime(scheduleTime) :'',
                    },
                    "serviceStatusUpdateDate": getFormatedDateTimeWithDecreasedTime(new Date()),
                }
            );

            if (response.status !== 200) {
                message.error(response.msg || "Failed to Schedule Service.");
            }
            setFetchTicketDataCnt(Math.random());
        } catch (error) {
            message.error("An error occurred while Scheduling Service.");
        } finally {
            setLoader(false);
            setScheduleTime(null);
        }
    }

    const handleStartService = () => {
        setStartServiceFlag(1);
    }

    const handleStartServiceDateConfirm = async () => {
        try {
            setLoader(true);
                const encodedArrivalTime = encodeURIComponent( arrivalTime ? getFormatedDateTimeWithoutChangingTime(arrivalTime) : '');

            const response = await postRequest(
                    `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/ticket/transfer-demanded-job-card-issue?ticketId=${ticketId}&arrivalTime=${encodedArrivalTime}`,
                    {}
            );

            if (response.status !== 201) {
                message.error(response.msg || "Failed to Start Service.");
            }
            setFetchTicketDataCnt(Math.random());
        } catch (error) {
            message.error("An error occurred while Starting Service.");
        } finally {
            setLoader(false);
            setArrivalTime(null);
        }
    }

    const handleCloseTicket = async () => {
        setCloseTicketOrResolveFlag(1);
    }

    const handleCloseTicketWithRemarkConfirmation = async () => {
        try {
            setLoader(true);
            const response = await putRequest(
                `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/ticket?id=${ticketId}`,
                {
                    "serviceStatus":"Cancelled",
                    "ticketRemark": closeTicketRemark,
                }
            );

            // setFetchTicketDataCnt(Math.random());
            if (response.status !== 200) {
                message.error(response.msg || "Failed to Close Ticket.");
            }
            setFetchTicketDataCnt(Math.random());
        } catch (error) {
            message.error("An error occurred while  Closing Ticket.");
        } finally {
            setLoader(false);
            setCloseTicketRemark('');
        }
    }
    const handleResolveTicketWithRemarkConfirmation = async () => {
        try {
            setLoader(true);
            const response = await putRequest(
                `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/ticket?id=${ticketId}`,
                {
                    "serviceStatus":"Service Resolved",
                    "ticketRemark": resolveTicketRemark,
                }
            );

            if (response.status !== 200) {
                message.error(response.msg || "Failed to Resolve Ticket.");
            }
            setFetchTicketDataCnt(Math.random());
        } catch (error) {
            message.error("An error occurred while  Resolving Ticket.");
        } finally {
            setLoader(false);
            setResolveTicketRemark('');
        }
    }

    const handleJobComplete = async () => {
        setShowJobCompleteModal(true)
    }

    const handleResolveService = async () => {
        const invoiceData = {
            "invoiceStatus": ticketDetails?.jobCard?.invoiceStatus,
            // "remarks": ticketDetails?.jobCard?.invoiceRemark
        }

        const isAllFilled = Object.values(invoiceData).every(value => value !== null && value !== undefined && value !== "")

        if (!isAllFilled) {
            message.error("Please fill all the details in Invoice Details before resolving the service.")
        } else {
            setCheckingIfCanResolveJob(true);
            await getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/ticket/actual-sub-issue-job-card?filterAnd=${encodeURIComponent(`jobCard.id|eq|${jobCardId}&active|eqb|true`)}`).then((res) => {
                console.log("resxxvv", res.data.actualJob.items)
                if (res.data.actualJob.items.length > 0) {
                    const issues = res.data.actualJob.items.map((item: any) => {
                        return {
                            "id": item?.id,
                            "status": item?.status,
                            "workType": item?.workType,
                            "quantity": item?.quantity,
                            "unitPrice": item?.unitPrice,
                            // "estimatedAmount": item?.estimatedAmount,
                            "discountedAmount": item?.discountedAmount,
                            "workDone": item?.workDone,
                            "basePrice": item?.basePrice,
                            "gst": item?.gst,
                            "gstAmount": item?.gstAmount,
                            "amountPayable": item?.amountPayable,
                        }
                    }).filter((item: any) => !(["Not Needed", "Added in New Ticket", "Not Resolved"].includes(item.status)))

                    const values = issues.map((item: any) => {
                        return {
                            "id": item?.id,
                            "status": item?.status,
                            "workType": item?.workType,
                            "quantity": Number(item?.quantity),
                            "unitPrice": Number(item?.unitPrice),
                            // "estimatedAmount": Number(item?.estimatedAmount),
                            "discountedAmount": Number(item?.discountedAmount),
                            "workDone": item?.workDone,
                            "basePrice": Number(item?.basePrice),
                            "gst": Number(item?.gst),
                            "gstAmount": Number(item?.gstAmount),
                            "amountPayable": Number(item?.amountPayable),
                        }
                    });

                    const allFilled = values.every((obj: any) =>
                        Object.values(obj).every(value => value !== null && value !== undefined && value !== "")
                    );

                    console.log(allFilled);

                    if (!allFilled) {
                        message.error("Please fill all the details in Job Card before completing the job.")
                    } else {
                        setShowResolveMoldal(true)
                    }
                }
            }).catch((err) => {
                console.log(err)
            })
            setCheckingIfCanResolveJob(false);
        }
    }

    return (
        <div style={{display: 'flex', justifyContent:'space-between'}}>
            <div>
                <div>
                    <LessthanIcon style={{margin: 0, padding: 0, cursor:'pointer'}} onClick={_ => navigate(-1)} />
                    <span style={{fontSize: '20px', fontWeight: '700', marginLeft: '1rem'}}>Ticket No. : {ticketNo}</span>
                    <span style={{color: ticketStatusColor, backgroundColor: ticketStatusBackgroundColor, marginLeft: '1.5rem',
                        padding:'8px', borderRadius:'4px', fontSize: '12px', fontWeight: '500'}}
                    >
                        {ticketStatus}
                    </span>
                </div>
                <div style={{color: '#616161', marginTop: '0.5rem'}}>
                    <span>Client Name : <span style={{color: "#1777FF"}}>
                        <a href={`/dashboard/prospect/${prospectId}`}  style={{color : '#1777FF' }}>
                            {prospectName}
                        </a>
                    </span></span>
                    <span style={{borderLeft: 'solid 2px #5A5A5A', marginLeft: '5px', paddingLeft:'5px'}}>
                        Last Updated at : {getFormattedDate(lastUpdatedAt)}
                    </span>
                </div>
            </div>
            <div>
                {
                    // Close ticket button will be there before status changes to in servicing
                        (ticketStatusId < STATUS_IDS.IN_SERVICING || ticketStatusId === STATUS_IDS.DATA_INSUFFICIENT ||
                            ticketStatusId === STATUS_IDS.SKIPPED) && 
                    <Button style={{
                        border: "solid 1px #FF4D4F",
                        color: "#FF4D4F",
                        background: "rgba(255, 77, 79, 0.10)"
                    }}
                        onClick={handleCloseTicket}
                    >
                        <CrossIcon style={{marginRight:'0.5rem'}}/>
                        Close Ticket
                    </Button>
                }
                {
                        ticketStatusId <= STATUS_IDS.TICKET_CREATED &&
                    <Button style={{
                        marginLeft:'0.8rem',
                            color: disableHeaderActions || disableScheduleService ? '#AFAFAF' : "#FFF",
                            background: disableHeaderActions || disableScheduleService ? '#CCC' :"#1777FF",
                    }}
                        disabled={ disableHeaderActions || disableScheduleService }
                        onClick={()=>setScheduleServiceFlag(1)}
                    >
                        Schedule Service
                    </Button>
                }
                {
                        ticketStatusId >= STATUS_IDS.SCHEDULED && ticketStatusId <= STATUS_IDS.SCHEDULED_OVERDUE && (
                        <Tooltip
                            title={isPickupAndDrop && !actualPickup ? 'Please enter Actual Pickup date before proceeding to start service' : ''}
                        >
                            <Button
                                style={{
                                    marginLeft: '0.8rem',
                                    color: disableHeaderActions || ( isPickupAndDrop && !actualPickup) ? '#AFAFAF' : "#FFF",
                                    background: disableHeaderActions ||  (isPickupAndDrop && !actualPickup) ? '#CCC' : "#1777FF",
                                }}
                                onClick={handleStartService}
                                disabled={disableHeaderActions ||  (isPickupAndDrop && !actualPickup)}
                            >
                                Start Service
                            </Button>
                        </Tooltip>
                    )
                }
                {
                        ticketStatusId == STATUS_IDS.IN_SERVICING &&
                    <Tooltip
                        title={(isPickupAndDrop && !deliverySchedule) ? 'Please enter Delivery Schedule date before proceeding to job complete' : ''}
                    >
                        <Button disabled={(isPickupAndDrop && !deliverySchedule)} style={{marginLeft: "0.8rem" }} onClick={handleJobComplete} type="primary">Job Complete</Button>
                    </Tooltip>
                }
                {
                        ticketStatusId ===  STATUS_IDS.JOB_COMPLETED &&
                        <Tooltip title={editingInvoiceForm || edititngForm ? 'Please save the changes in Job Card before resolving the service' : ''}>
                            <Button loading={checkingIfCanResolveJOb} disabled={checkingIfCanResolveJOb || editingInvoiceForm || edititngForm} style={{marginLeft: "0.8rem"}} onClick={handleResolveService} type="primary">Resolve Service</Button>
                        </Tooltip>
                }

            </div>
            <StartServiceConfirmationModal startServiceFlag={startServiceFlag} setStartServiceFlag={setStartServiceFlag}
                     handleStartServiceDateConfirm={handleStartServiceDateConfirm} isPickupAndDrop = {isPickupAndDrop}/>
                <ServiceArrivalTimeConfirmationModal startServiceFlag={startServiceFlag} setStartServiceFlag={setStartServiceFlag}
                    arrivalTime={arrivalTime} setArrivalTime={setArrivalTime} handleStartServiceDateConfirm={handleStartServiceDateConfirm}/>
                <ScheduleServiceConfirmationModal scheduleServiceFlag={scheduleServiceFlag} setScheduleServiceFlag={setScheduleServiceFlag}
                    handleScheduleServiceDateConfirm={handleScheduleServiceDateConfirm} isPickupAndDrop = {isPickupAndDrop}/>
                <ServiceScheduleTimeConfirmationModal scheduleServiceFlag={scheduleServiceFlag} setScheduleServiceFlag={setScheduleServiceFlag}
                    scheduleTime={scheduleTime}  setScheduleTime={setScheduleTime} handleScheduleServiceDateConfirm={handleScheduleServiceDateConfirm}/>
                <CompleteJobModal ticketId={ticketId} open={showJobCompleteModal} setOpen={setShowJobCompleteModal} />
                <ResolveJobModal prospectId={prospectId} jobCardId={jobCardId}  open={showResolveModal} setOpen={setShowResolveMoldal} />
                <CloseTicketCancelOrResolveModel closeTicketOrResolveFlag={closeTicketOrResolveFlag} setCloseTicketOrResolveFlag={setCloseTicketOrResolveFlag}/>
                <CloseTicketWithRemarkModal closeTicketOrResolveFlag={closeTicketOrResolveFlag} setCloseTicketOrResolveFlag={setCloseTicketOrResolveFlag}
                    closeTicketRemark={closeTicketRemark} setCloseTicketRemark={setCloseTicketRemark} 
                    handleCloseTicketWithRemarkConfirmation={handleCloseTicketWithRemarkConfirmation}/>
                <ResolveTicketWithRemarkModal closeTicketOrResolveFlag={closeTicketOrResolveFlag} setCloseTicketOrResolveFlag={setCloseTicketOrResolveFlag}
                    resolveTicketRemark={resolveTicketRemark} setResolveTicketRemark={setResolveTicketRemark} 
                    handleResolveTicketWithRemarkConfirmation={handleResolveTicketWithRemarkConfirmation}/>
        </div>
    );
}
export default TicketDetailsHeader;