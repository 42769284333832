import { Card, Select, Typography, message, Button } from "antd";
import { useEffect, useState } from "react";
import IssuesAndSubIssuesTable from "./IssuesAndSubIssuesTable";
import { ReactComponent as CrossIcon } from './../../../../../../utils/icons/Cross_Icon.svg';
import AddIssues from "./AddIssues";
import { ReactComponent as EyeIcon } from "./../../../../../../utils/icons/EyeIcon.svg";
import { ReactComponent as PipeIcon } from "./../../../../../../utils/icons/Pipe_Icon.svg";
import IssueAndSubissueHistoryModal from "./IssueAndSubissueHistoryModal";
import { postRequest } from "../../../../../../utils/handler/apiHandler";
import { BlockLike } from "typescript";

const { Title } = Typography;

interface IssuesAndSubIssuesProps {
    issueSubissueTableData: any;
    fetchTicketDataCnt: number;
    setFetchTicketDataCnt: (cnt: number) => void;
    jobCardId: number;
    ticketId: string;
    showAddIssue: boolean;
    setShowAddIssue: (flag: boolean) => void;
    editableWorkshop: boolean,
    editableTicket: boolean,
    serviceStatusUpdateDate: string,
    ticketStatusId: number,
}

const IssuesAndSubIssues: React.FC<IssuesAndSubIssuesProps> = ({
    issueSubissueTableData,
    fetchTicketDataCnt,
    setFetchTicketDataCnt,
    jobCardId,
    ticketId,
    showAddIssue,
    setShowAddIssue,
    editableWorkshop,
    editableTicket,
    serviceStatusUpdateDate,
    ticketStatusId
}) => {
    const [showVersionHistory, setShowVersionHistory] = useState<boolean>(false);
    const [disableShowVersionHistory, setDisableShowVersionHistory] = useState<boolean>(true);
    const [selectedIssues, setSelectedIssues] = useState<any[]>([]); 
    const [issueComponents, setIssueComponents] = useState<any[]>([]); // Store multiple AddIssues components
    const CANCELLED_ID = 11, SERVICE_RESOLVED_ID = 12, IN_SERVICING_ID = 8;

    useEffect(() => {
        // If ticket status is 10 or 13, reset the issue components and hide the add issue button
        if (ticketStatusId === CANCELLED_ID || ticketStatusId === SERVICE_RESOLVED_ID) {
            setIssueComponents([]);
            setShowAddIssue(false);
            return; // No need to check further
        }
    
        // If there are no issue subissues and no issue components, add a new issue component
        if ((!issueSubissueTableData || issueSubissueTableData.length === 0) &&
            (!issueComponents || issueComponents.length === 0)) {
            addNewIssueComponent();
        } else {
            // If the conditions don't match, reset the issue components and hide the add issue button
            setIssueComponents([]);
            setShowAddIssue(false);
        }
    }, [issueSubissueTableData, ticketStatusId]);

    useEffect(()=>{
        if ((!issueSubissueTableData || issueSubissueTableData.length === 0) &&
            (!issueComponents || issueComponents.length === 0)) {
            addNewIssueComponent();
        }
    },[showAddIssue]);
    

    const onCloseVersionHistory = () => {
        setShowVersionHistory(false);
    };

    const addNewIssueComponent = () => {
        setIssueComponents([
            ...issueComponents,
            { id: Date.now() }, // Unique ID for each issue component
        ]);
        setShowAddIssue(true); // Show AddIssue component when new issue is added
    };

    const removeIssueComponent = (id: number) => {
        setIssueComponents(issueComponents.filter((item) => item.id !== id));
    };

    const saveAllIssuesAndSubissues = async () => {
        // Check if all issues and subissues are selected
        setIssueComponents([]);
        setShowAddIssue(false);
    
        const invalidSelection = selectedIssues.some((item) => 
            !item.issueId || !Array.isArray(item.subIssueId) || item.subIssueId.length === 0
        );
        if (invalidSelection || selectedIssues.length < issueComponents.length) {
            message.error("Please select both Issue and Subissue for all entries.");
            return;
        }
    
        try {
            const payload = selectedIssues.flatMap((item) => 
                item.subIssueId.map((subIssue:any) => ({
                    subIssueId: subIssue,
                    status: 'Reported',
            })));
    
            const response = await postRequest(
                `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/ticket/job-card-issue?ticketId=${ticketId}`,
                payload
            );
    
            if (response.status !== 201) {
                message.error(response.msg || "Failed to add Issue and Subissue");
            }
        } catch (error) {
            message.error("An error occurred while adding Issue and Subissue");
        } finally {
            setShowVersionHistory(false);
            setFetchTicketDataCnt(Math.random());

        }
    };  

    return (
        <div>
            <div
                style={{
                    display: "flex",
                    marginTop: "20px",
                    justifyContent: "space-between",
                }}
            >
                <Title style={{ fontWeight: 700 }} level={5}>
                    Issues & Sub - Issues
                </Title>
                <div style={{ display: 'flex' }}>
                    {!disableShowVersionHistory && (
                        <>
                            <div
                                style={{ display: 'flex', cursor: 'pointer' }}
                                onClick={() => setShowVersionHistory(true)}
                            >
                                <EyeIcon style={{ marginTop: '0.25rem' }} />
                                <span
                                    style={{
                                        marginLeft: '0.5rem',
                                        paddingRight: '0.8rem',
                                        color: '#2C66E3',
                                        fontSize: '12px',
                                        fontWeight: '600',
                                    }}
                                >
                                    Show Version History
                                </span>
                            </div>
                            <PipeIcon style={{ marginLeft: '0.4rem', marginTop: '0.05rem' }} />
                        </>
                    )}

                    {
                        !(ticketStatusId === CANCELLED_ID || ticketStatusId === SERVICE_RESOLVED_ID) &&  
                        <div style={{ marginLeft: '1rem', display:'flex' }}>
                        <span
                            style={{
                                marginRight: '0.6rem',
                                marginLeft: '0.6rem',
                                color: (ticketStatusId < IN_SERVICING_ID  && !editableTicket && !editableWorkshop) ? '#2C66E3' : 'grey',
                                fontSize: '12px',
                                fontWeight: '600',
                                cursor: (ticketStatusId < IN_SERVICING_ID  && !editableTicket && !editableWorkshop) ? 'pointer' : 'not-allowed', // Use 'not-allowed' when not clickable
                            }}
                            onClick={(e: any) => {
                                if (ticketStatusId < IN_SERVICING_ID  && !editableTicket && !editableWorkshop) {
                                    addNewIssueComponent(); // Call the function only if clickable
                                }
                            }}
                        >
                            + Add More
                        </span>

                    </div>
                    }
                    
                    {
                        (issueComponents && issueComponents.length) ?
                        <div style={{display:'flex'}}>
                            <div>
                            <PipeIcon style={{marginLeft: '0.5rem', marginRight: '0.8rem', marginTop: '0.05rem' }} />
                            </div>
                            <div
                                style={{
                                    marginLeft: "0.5rem",
                                    marginRight: "'0.8rem'",
                                    color: "#FF4D4F",
                                    fontSize: "12px",
                                    fontWeight: "600",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    setIssueComponents([]);
                                    setShowAddIssue(false);
                                }}
                            >
                                <CrossIcon style={{marginRight:'0.3rem'}}/>
                                Cancel
                            </div>
                        </div>
                        : ''
                        
                    }
                    
                </div>
            </div>

            {/* Dynamically Render AddIssues Components */}
            {
                issueComponents.length !== 0 && 
                <Card>
                    {
                        issueComponents.map((component, index) => (
                            <AddIssues
                                key={component.id}
                                selectedIssues={selectedIssues}
                                setSelectedIssues={setSelectedIssues}
                                index={index} // Pass index to identify each dynamically added issue-subissue pair
                                ticketId={ticketId}
                            />
                        ))
                    }
                    <div style={{ marginTop: '1rem', display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            style={{
                                color: 'rgba(255, 255, 255, 1)',
                                backgroundColor: '#1777FF',
                                borderColor: '#1777FF',
                            }}
                            onClick={saveAllIssuesAndSubissues}
                        >
                            Save Issues & Sub - Issues
                        </Button>
                    </div>

                </Card>
            }
            {
                issueSubissueTableData && issueSubissueTableData.length !== 0 && 
                <Card style={{ marginTop: '20px' }}>
                    <div style={{  }}>
                        <IssuesAndSubIssuesTable
                            issueSubissueTableData={issueSubissueTableData}
                            setFetchTicketDataCnt={setFetchTicketDataCnt}
                            ticketId={ticketId}
                            jobCardId={jobCardId}
                            serviceStatusUpdateDate={serviceStatusUpdateDate}
                        />
                    </div>
                </Card>
            }
            

            <IssueAndSubissueHistoryModal
                showVersionHistory={showVersionHistory}
                onCloseVersionHistory={onCloseVersionHistory}
                jobCardId={jobCardId}
                ticketId={ticketId}
                setFetchTicketDataCnt={setFetchTicketDataCnt}
                fetchTicketDataCnt={fetchTicketDataCnt}
                setDisableShowVersionHistory={setDisableShowVersionHistory}
            />

            
        </div>
    );
};

export default IssuesAndSubIssues;
