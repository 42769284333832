import { Button, Card, DatePicker, Input, Modal, message } from "antd";
import { useEffect, useState } from "react";

import { ReactComponent as CheckIcon } from "./../../../../../utils/icons/CheckIconConfigurable.svg";
import { ReactComponent as CloseIcon } from "./../../../../../utils/icons/CloseIconBlack.svg";
import { ReactComponent as DisableCloseIcon } from "./../../../../../utils/icons/Disabled_Cross_Icon.svg";
import { ReactComponent as ArrowLeft } from "./../../../../../utils/icons/arrow-left.svg";
import { ReactComponent as CheckWhite } from "./../../../../../utils/icons/CheckWhite.svg";
import { margin } from "@mui/system";

interface ResolveTicketWithRemarkModalProps{
    closeTicketOrResolveFlag: number,
    setCloseTicketOrResolveFlag: (flag: number) => void,
    resolveTicketRemark: string,
    setResolveTicketRemark: (date: string) => void,
    handleResolveTicketWithRemarkConfirmation: ()=>void,
}

const ResolveTicketWithRemarkModal: React.FC<ResolveTicketWithRemarkModalProps> = ({ resolveTicketRemark, closeTicketOrResolveFlag, 
        setCloseTicketOrResolveFlag , setResolveTicketRemark, handleResolveTicketWithRemarkConfirmation }) => {
    
    return (
        <Modal 
            open={closeTicketOrResolveFlag === 3} 
            onCancel={() => {
                setCloseTicketOrResolveFlag(0);
                setResolveTicketRemark('')
                }
            }
            width='30%'
            footer={null} // Removes default OK and Cancel buttons
            closeIcon={<CloseIcon  />} // Custom close icon with red color
            centered={true}
            >
            <div style={{display:'flex', flexDirection: 'column'}}>

            
                <div style={{display:'flex', marginTop:'1rem'}}>
                    <p style={{ fontWeight: '700', fontSize: '1rem', color:'#404040' }}>
                        Are you sure you want to Resolve the Ticket?
                    </p>
                </div>
                <div style={{ display:'flex', flexDirection:'column', alignItems:'center', margin:'1rem 0rem', fontWeight: '500', fontSize: '0.875rem', color:'#333' }}>
                    <div style={{width:'100%'}}>
                    <Input 
                        placeholder="Enter Remarks"
                        value={resolveTicketRemark}
                        onChange={(e: any) => setResolveTicketRemark(e.target.value)} 
                        style={{ width: '100%' }} 
                        />
                     </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent:'flex-end', marginBottom:'0.8rem' }}>
                    <Button style={{width:'30%', color:'#1777FF', borderColor: '#1777FF', backgroundColor:'#E0EDFF',
                        height:'2.2rem'}}
                        onClick={()=> {
                            setCloseTicketOrResolveFlag(0);
                            setResolveTicketRemark('')
                            }
                        }>
                        <span style={{fontSize:'12px', fontWeight:'500'}}>
                            Don't Resolve
                        </span>
                    </Button>

                    <Button style={{width:'30%', color: resolveTicketRemark === '' ? '#AFAFAF' :'#FFF', 
                        borderColor: resolveTicketRemark === '' ? '#DCDCDC' : '#2C66E3', 
                        backgroundColor: resolveTicketRemark === '' ? '#DCDCDC' : '#2C66E3',
                        marginLeft:'1.2rem', height:'2.2rem'}}
                        onClick={()=> {
                            setCloseTicketOrResolveFlag(0);
                            handleResolveTicketWithRemarkConfirmation();
                        }}
                        disabled={resolveTicketRemark === '' ? true : false}
                        >
                        <CheckIcon color= {resolveTicketRemark === '' ? '#AFAFAF' :"#fff"}/>
                        <span style={{fontSize:'12px', fontWeight:'500'}}>
                            Resolve 
                        </span>
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default ResolveTicketWithRemarkModal;
