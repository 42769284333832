import { Tooltip, Select } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { getStatusStyles } from './getStatusStyles';

import { getFormattedDate } from '../../../../../../utils/helpers/utils';

const { Option } = Select;

export const getJobCardLogsTableColumns = (ticketStatusId?: number): ColumnsType<any> => {


  return [
    {
      title: (
        <div style={{ display: 'flex', marginLeft: '5px' }}>
          <span style={{}}>Issue</span>
        </div>
      ),
      dataIndex: 'issue',
      key: 'issue',
      render: (text: string, record: any) => (
        <Tooltip title={text}>
          <div style={{
            width: '100%', overflow: 'hidden', textOverflow: 'ellipsis',
            whiteSpace: 'nowrap', color: record?.issueMod
              ? "#FFA800"
              : "rgba(64, 64, 64, 1)"
          }}>
            {text}
          </div>
        </Tooltip>
      ),
    },
    {
      title: (
        <div style={{ display: 'flex' }}>
          <span style={{}}>Sub Issue</span>
        </div>
      ),
      dataIndex: 'subissue',
      key: 'subissue',
      render: (text: string, record: any) => (
        <Tooltip title={text}>
          <div style={{
            width: '100%', overflow: 'hidden', textOverflow: 'ellipsis',
            whiteSpace: 'nowrap', color: record?.subissueMod
              ? "#FFA800" : "rgba(64, 64, 64, 1)"
          }}>
            {text}
          </div>
        </Tooltip>
      ),
    },
    {
      title: <div style={{ display: 'flex' }}>Work Type</div>,
      dataIndex: 'workType',
      key: 'workType',
      render: (text: string, record: any) => {
        console.log("text", record); return (
          <div style={{
            color: record?.workTypeMod
              ? "#FFA800" : "rgba(64, 64, 64, 1)"
          }}>
            {text ? text : "---"}
          </div>
        )
      }
    },
    {
      title: <div style={{ display: 'flex' }}>Quantity</div>,
      dataIndex: 'quantity',
      key: 'quantity',
      render: (text: number, record: any) => (
        <div style={{
          color: record?.quantityMod
            ? "#FFA800" : "rgba(64, 64, 64, 1)"
        }}>
          {text || text === 0 ? text : "---"}

        </div>
      )
    },
    {
      title: <div style={{ display: 'flex' }}>Unit Price</div>,
      dataIndex: 'unitPrice',
      key: 'unitPrice',
      render: (text: number, record: any) => (
        <div style={{
          color: record?.unitPriceMod
            ? "#FFA800" : "rgba(64, 64, 64, 1)"
        }}>
          {text || text === 0 ? text : "---"}

        </div>
      )
    },
    // {
    //   title: <div style={{ display: 'flex' }}>Estimated Amount</div>,
    //   dataIndex: 'estimatedAmount',
    //   key: 'estimatedAmount',
    //   render: (text: number, record: any) => (
    //     <div style={{
    //       color: record?.estimatedAmountMod
    //         ? "#FFA800" : "rgba(64, 64, 64, 1)"
    //     }}>
    //       {text || text === 0 ? text : "---"}

    //     </div>
    //   )
    // },
    {
      title: <div style={{ display: 'flex' }}>Discounted Amount</div>,
      dataIndex: 'discountedAmount',
      key: 'discountedAmount',
      render: (text: number, record: any) => (
        <div style={{
          color: record?.discountedAmountMod
            ? "#FFA800" : "rgba(64, 64, 64, 1)"
        }}>
          {text || text === 0 ? text : "---"}
        </div>
      )
    },
    {
      title: <div style={{ display: 'flex' }}>Taxable Amount</div>,
      dataIndex: 'taxableAmount',
      key: 'taxableAmount',
      render: (text: number, record: any) => (
        <div style={{
          color: record?.basePriceMod || record?.discountedAmountMod
            ? "#FFA800" : "rgba(64, 64, 64, 1)"
        }}>
          {text || text === 0 ? text : "---"}
        </div>
      )
    },
    {
      title: <div style={{ display: 'flex' }}>Amount Payable</div>,
      dataIndex: 'amountPayable',
      key: 'amountPayable',
      render: (text: number, record: any) => (
        <div style={{
          color: record?.amountPayableMod
            ? "#FFA800" : "rgba(64, 64, 64, 1)"
        }}>
          
          <span style={{
            fontWeight: "bold",
          color: record?.isRecoverableMod
            ? "#FFA800" : "rgba(64, 64, 64, 1)"
          }}>
            {record?.isRecoverable ? "R " : "NR "}
          </span>
          {text || text === 0 ? text : "---"}

        </div>
      )
    },
    {
      title: <div style={{ display: 'flex' }}>Base Price</div>,
      dataIndex: 'basePrice',
      key: 'basePrice',
      render: (text: number, record: any) => (
        <div style={{
          color: record?.basePriceMod
            ? "#FFA800" : "rgba(64, 64, 64, 1)"
        }}>
          {text || text === 0 ? text : "---"}

        </div>
      )
    },
    {
      title: <div style={{ display: 'flex' }}>GST Percentage </div>,
      dataIndex: 'gst',
      key: 'gst',
      render: (text: number, record: any) => (
        <div style={{
          color: record?.gstMod
            ? "#FFA800" : "rgba(64, 64, 64, 1)"
        }}>
          {text || text === 0 ? text+" %" : "---"}

        </div>
      )
    },
    {
      title: <div style={{ display: 'flex' }}>GST Amount </div>,
      dataIndex: 'gstAmount',
      key: 'gstAmount',
      render: (text: number, record: any) => (
        <div style={{
          color: record?.gstAmountMod
            ? "#FFA800" : "rgba(64, 64, 64, 1)"
        }}>
          {text || text === 0 ? text : "---"}

        </div>
      )
    },

    {
      title: <div style={{ display: 'flex' }}>Work Done </div>,
      dataIndex: 'workDone',
      key: 'workDone',
      render: (text: string, record: any) => (
        <div style={{
          color: record?.workDoneMod
            ? "#FFA800" : "rgba(64, 64, 64, 1)"
        }}>
          {text ? text : "---"}

        </div>
      )
    },
    {
      title: <div style={{ display: 'flex' }}>Status </div>,
      dataIndex: 'status',
      key: 'status',
      render: (text: any, record: any) => {
        const color = record.statusColor;
        const backgroundColor = record.backgroundColor;
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Tooltip title={text}>
              <div style={{
                color: color, fontWeight: '500', backgroundColor: backgroundColor, padding: '0 8px',
                borderRadius: '4px', display: 'inline-block', maxWidth: 160, overflow: 'hidden', textOverflow: 'ellipsis',
                border: record.statusMod ? '1px solid orange' : "unset",
                whiteSpace: 'nowrap'
              }}>
                {text || text === 0 ? text : "---"}
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: <div style={{ display: 'flex' }}>Reported At </div>,
      dataIndex: 'reportedAt',
      key: 'reportedAt',
      render: (text: string, record: any) => (
        <div style={{
          width: '100%', overflow: 'hidden', textOverflow: 'ellipsis',
          whiteSpace: 'nowrap', color: record?.reportedAtMod
            ? "#FFA800" : "rgba(64, 64, 64, 1)"
        }}>
          {text != null ?
            `${getFormattedDate(text)} ` :
            <span style={{ fontSize: 12 }}>NA</span>
          }
        </div>
      )

    },




    {
      title: <div style={{ display: 'flex' }}>Last Updated At </div>,
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text: string, record: any) => (
        <div style={{
          width: '100%', overflow: 'hidden', textOverflow: 'ellipsis',
          whiteSpace: 'nowrap', color: false
            ? "#FFA800" : "rgba(64, 64, 64, 1)"
        }}>
          {text != null ?
            `${getFormattedDate(text)} ` :
            <span style={{ fontSize: 12 }}>NA</span>
          }
        </div>
      ),
      sortDirections: ['ascend', 'descend'],
    },
  ]?.filter((item) => {
    if (ticketStatusId === 8) {
      return item.dataIndex !== "gst" && item.dataIndex !== "gstAmount" && item.dataIndex !== "basePrice" && item.dataIndex !== "amountPayable"
    } else {
      return item;
    }
  }) as ColumnsType;
};
