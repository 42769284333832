import { Card, Typography } from "antd";

interface ServiceTakenDetailsProps{
    serviceTakenList: string[]
}


const { Title } = Typography;

const ServiceTakenDetails: React.FC<ServiceTakenDetailsProps> = ({serviceTakenList})=>{
    return(
        <>
            <Title style={{ marginTop: '20px', fontWeight: 700 }} level={5}>
                Service Taken
            </Title>

            <Card style={{ width: '100%' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    {serviceTakenList?.map((serviceName: string, index: number) => (
                    <span key={index} style={{ width: '25%' }}>
                        {serviceName}
                    </span>
                    ))}
                </div>
            </Card>
        </>
       
    );
}

export default ServiceTakenDetails;